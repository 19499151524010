import { APP_DATA_CONTEXT, FEATURED_POOLS, FEATURED_POOLS_MANAGER_CONTEXT, FEATURED_POOL_DATA, LOCAL_TOKEN_DATA, POOL_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
//@ts-ignore
// import gradient1 from "../../img/gradients/gradient1.jpg";
import "./PoolCategories.css";
import { useContext, useEffect, useState } from "react";
import { defaultChain, fetchLocalTokenData, mobileBreakpoint, shouldRenderPool } from "../../utils/Utils";
import { WalletDataContext } from "../../context/WalletDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import { AppDataContext } from "../../context/AppDataContext";
import { ethers } from "ethers";
import LineTabs from "../LineTabs/LineTabs";
import { FeaturedPoolsManagerContext } from "../../context/FeaturedPoolsManagerContext";
import { useScreenSize } from "../../utils/useScreenSize";

const PoolCategories = (props: {
  pools: any
}) => {

  let defaultCategories = {
    1: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        name: "Featured Pools",
        filterMethod: (pools: POOL_DATA[], _featuredPoolList?: any) => featuredFilter(pools, _featuredPoolList) 
      },
      {
        name: "Most Funded",
        filterMethod: (pools: POOL_DATA[], fullPoolData?: any) => mostFundedFilter(pools, fullPoolData)
      },
      {
        name: "New Pools",
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools) 
      },
    ],
    8453: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        name: "Featured Pools",
        filterMethod: (pools: POOL_DATA[], _featuredPoolList?: any) => featuredFilter(pools, _featuredPoolList) 
      },
      {
        name: "Most Funded",
        filterMethod: (pools: POOL_DATA[], fullPoolData?: any) => mostFundedFilter(pools, fullPoolData)
      },
      {
        name: "New Pools",
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools) 
      },
    ],
    5001: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        name: "Featured Pools",
        filterMethod: (pools: POOL_DATA[], _featuredPoolList?: any) => featuredFilter(pools, _featuredPoolList) 
      },
      {
        name: "Most Funded",
        filterMethod: (pools: POOL_DATA[], fullPoolData?: any) => mostFundedFilter(pools, fullPoolData)
      },
      {
        name: "New Pools",
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools) 
      },
    ],
    5: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
    ],
    137: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
    ],
    42161: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        name: "Featured Pools",
        filterMethod: (pools: POOL_DATA[], _featuredPoolList?: any) => featuredFilter(pools, _featuredPoolList) 
      },
      {
        name: "Most Funded",
        filterMethod: (pools: POOL_DATA[], fullPoolData?: any) => mostFundedFilter(pools, fullPoolData)
      },
      {
        name: "New Pools",
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools) 
      },
    ],
    421613: [
      {
        name: "All Pools",
        filterMethod: (pools: POOL_DATA[]) => allPoolsFilter(pools) 
      },
      {
        name: "Featured Pools",
        filterMethod: (pools: POOL_DATA[], _featuredPoolList?: any) => featuredFilter(pools, _featuredPoolList) 
      },
      {
        name: "New Pools",
        filterMethod: (pools: POOL_DATA[]) => newPoolsFilter(pools) 
      },
    ]
  }

  // import context variables
  const { tokenPrices, liquidityRange, aprRange, dateRange, calculateLTV, fullPoolData } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { selectedFeaturedPools, setSelectedFeaturedPools } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { isPoolFeatured, featuredPoolsList } = useContext(FeaturedPoolsManagerContext) as FEATURED_POOLS_MANAGER_CONTEXT;
  const { screenWidth } = useScreenSize();
  // @ts-ignore
  const [poolCategories, setPoolCategories] = useState<FEATURED_POOLS[]>(defaultCategories[chainId ? chainId : defaultChain]);
  const [poolCounts, setPoolCounts] = useState<any>({});

  // update featured pools list if chainId updates
  useEffect(() => {
    // @ts-ignore
    const chainCategories = defaultCategories[chainId ? chainId : defaultChain];
    const defaultSelection = chainCategories.find((featured: FEATURED_POOLS) => featured.name === "Featured Pools");
    // don't reset filter once it's set (TODO: this may not work with multichain)
    if (!selectedFeaturedPools) {
      setSelectedFeaturedPools(defaultSelection);
    }
    // set the categories
    setPoolCategories(chainCategories);
  // eslint-disable-next-line
  }, [chainId, tokenPrices, featuredPoolsList]);

  useEffect(() => {
    // get number of pools for each category
    if (props.pools.length > 0)
      updatePoolCount();
  // eslint-disable-next-line
  }, [props.pools, selectedFeaturedPools, liquidityRange, aprRange, dateRange, fullPoolData, featuredPoolsList]);



  const newPoolsFilter = (pools: POOL_DATA[]) => {
    const now = Date.now();
    const blacklist = [
      "0x2707aa1d3e1aa2ef89ac0bac0aefa15e97a38b9f",
      "0x144fa110f2d8506c187af7c876ba0c3bd14eb674"
    ].map((address: string) => address.toLowerCase());
    return pools
      .filter((pool: POOL_DATA) => {
        try {
          const isJunkPool = pool.lendBalance === "0" && pool.colBalance === "0";
          const isPrivate = pool.borrowers.length > 0;
          const isBlacklisted = blacklist.includes(pool.id.toLowerCase());
          const isLive =
            fetchLocalTokenData(pool.colToken, chainId) !== undefined &&
            fetchLocalTokenData(pool.lendToken, chainId) !== undefined;
          // filter for pools created in the last 3 days
          const isNew = Number(pool.startTime) > (now - 1000 * 60 * 60 * 24 * 3)/1000 && Number(pool.startTime) < now/1000;
          return (
            isNew &&
            !isJunkPool && 
            !isPrivate &&
            !isBlacklisted &&
            isLive
          );
        } catch (e) {
          return false;
        }
      })
      .map((pool: POOL_DATA) => pool.id);
  }

  // filter for funded pools
  const mostFundedFilter = (pools: POOL_DATA[], _tokenPrices: any) => {
    return pools.filter((pool: POOL_DATA) => {
      try {
        // remove empty items from the borrowers array
        const borrowers = pool.borrowers.filter((b: any) => b !== "");
        if (borrowers.length > 0) return false;
        // get lend/col token balance and value
        let lendData:LOCAL_TOKEN_DATA = fetchLocalTokenData(pool.lendToken, chainId) as any;
        const lendBalance = ethers.utils.formatUnits(pool.lendBalance, lendData.tokenDecimals);
        const lendPrice = _tokenPrices[ethers.utils.getAddress(pool.lendToken)];
        let lendValue = Math.floor(parseFloat(lendBalance) * parseFloat(lendPrice) * 100) / 100;
        if (isNaN(lendValue)) lendValue = 0;
        return lendValue > 2000;
      } catch (e) {
        return false;
      }
    }).map((pool: POOL_DATA) => pool.id)
  }

  // filter for all pools
  const allPoolsFilter = (pools: POOL_DATA[]) => {
    const oldUSDT = "0xbAc565f93f3192D35E9106E67B9d5c9348bD9389".toLowerCase();
    return pools
      .filter((pool: POOL_DATA) => pool.lendToken !== oldUSDT && pool.colToken !== oldUSDT)
      .map((pool: POOL_DATA) => pool.id.toLowerCase());
  }

  // filter for pools we want to feature
  const featuredFilter = (pools: POOL_DATA[], _featuredPoolList?: FEATURED_POOL_DATA[]) => {
    // tokens we want to feature
    // list of pools we want to filter
    const whitelist = [
      "0x7d932638572ec511f355cf5972a31ef602b75107",
      "0x5a620e42482590e0429ff5fefd43a747b44eed76",
      "0xf11ad43cd8effb0bd23370a875e5d74323b73a76",
      "0xC112D186677E122a4661E8FB29fdE98b2e6542F5",
      "0x3CAd78A5949A6F78B58F2d58E7C34B7a4bf29D1E"
    ].map((pool: string) => pool.toLowerCase());
    return pools.filter((pool: POOL_DATA) => {
      const isPaidFeatured = isPoolFeatured(pool.id, _featuredPoolList);
      const isInWhitelist = whitelist.includes(pool.id.toLowerCase());
      return isPaidFeatured || isInWhitelist;
    }).map((pool: POOL_DATA) => pool.id);
  }

  // get number of pools for each category
  const updatePoolCount = () => {
    let localCounts:any = {};
    for (const [, data] of poolCategories.entries()) {
      // go through a general filter based on liquidity range, apr range, and date range, etc.
      let featuredList = props.pools.filter((pool: POOL_DATA) => 
        shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          "",
          liquidityRange,
          aprRange,
          dateRange,
          "",
          calculateLTV(pool),
          true
        )
      );
      // filter based on specific category
      if (data.name === "Most Funded") 
        featuredList = data.filterMethod(props.pools, tokenPrices);
      else if (data.name === "Featured Pools")
        featuredList = data.filterMethod(props.pools, featuredPoolsList);
      else 
        featuredList = data.filterMethod(props.pools);

      let count = 0;
      // count the number of pools in the category
      for (const pool of props.pools) {
        // check if the pool is expired
        const isExpired = Number(pool.expiry) < Date.now() / 1000;
        // remove empty items from the borrowers array
        const borrowers = pool.borrowers.filter((b: any) => b !== "");
        let isPrivate = borrowers.length > 0;
        let userWhitelisted = !isPrivate;
        // check if the user is whitelisted for a private pool
        if (isPrivate && borrowers.includes(account || ""))
          userWhitelisted = true;
        if (
          featuredList.includes(pool.id.toLowerCase()) && 
          !isExpired &&
          userWhitelisted
        )
          count++;
      }
      // update the count and store it in localCounts
      localCounts = {
        ...localCounts,
        [data.name]: count
      }
    }
    // update the pool counts state
    setPoolCounts(localCounts);
  }

  const tabClicked = (index: number) => {
    setSelectedFeaturedPools(poolCategories[index]);
  }

  const getSelectedCategory = () => {
    if (selectedFeaturedPools) {
      const index = poolCategories.findIndex((category: FEATURED_POOLS) => category.name === selectedFeaturedPools.name);
      return index;
    }
    return 0;
  }

  const renderTabTitle = (category: FEATURED_POOLS) => {
    let name = category.name;
    if (screenWidth < mobileBreakpoint)
      name = name.split(" ")[0];
    return (
      <div className="tab-title-wrapper">
        <span className={`tab-name ${selectedFeaturedPools?.name === category.name ? "active" : ""}`}>{name}</span> 
        <span className="tab-value">{poolCounts[category.name] || 0}</span>
      </div>
    )
  }

  return (
    <div className="featured-pools-wrapper">
      <LineTabs
        tabs={poolCategories.map((category: FEATURED_POOLS) => {
          return (renderTabTitle(category))
        })}
        activeTab={getSelectedCategory()}
        setActiveTab={tabClicked}
      />
    </div>
  )

};

export default PoolCategories;